import { CroppableImage, PrismicImage } from '@hugsmidjan_is/prismic/types'
import { IPrismicImage } from 'prismic/types/image'

import { PictureFormats } from '../components/picture/Picture'

export type WidthHeight = {
  width: number
  height: number
  media?: string
}

export type ScreenSizes = {
  wide: WidthHeight
  desktop: WidthHeight
  tablet: WidthHeight
  mobile: WidthHeight
}

export type ViewportSizes = ScreenSizes | { x1: WidthHeight }

export type Breakpoints = {
  wideMedia: string
  desktopMedia: string
  tabletMedia: string
  mobileMedia: string
}

export const defaultBreakpoints: Breakpoints = {
  wideMedia: '(min-width: 1400px)',
  desktopMedia: '(min-width: 1080px) and (max-width: 1399px)',
  tabletMedia: '(min-width: 720px) and (max-width: 1080px)',
  mobileMedia: '(max-width: 719px)',
}

// Default image sizes if they are not supplied by component that wants
// to use cleverCrop
export const defaultImageSizes: ScreenSizes = {
  wide: {
    width: 1900,
    height: 800,
  },
  desktop: {
    width: 1200,
    height: 800,
  },
  tablet: {
    width: 1000,
    height: 500,
  },
  mobile: {
    width: 700,
    height: 700,
  },
}

type GenerateImageSizesType = {
  ratio: number
  wide: number
  desktop?: number
  tablet?: number
  mobile?: number
}

export function generateImageSizes({
  ratio,
  wide,
  desktop,
  tablet,
  mobile,
}: GenerateImageSizesType): ViewportSizes {
  return {
    wide: {
      width: wide,
      height: Math.round(wide * ratio),
    },
    desktop: {
      width: desktop ?? wide,
      height: Math.round((desktop ?? wide) * ratio),
    },
    tablet: {
      width: tablet ?? wide,
      height: Math.round((tablet ?? wide) * ratio),
    },
    mobile: {
      width: mobile ?? wide,
      height: Math.round((mobile ?? wide) * ratio),
    },
  }
}

export function imageAsCroppableImage(
  image: PrismicImage | IPrismicImage
): CroppableImage | null {
  if ((image as CroppableImage)?.focus_point) {
    return image as CroppableImage
  } else {
    return null
  }
}

type CreateFormatParams = {
  formats: PictureFormats
  breakpoints?: Breakpoints
}

export type FormatArray = Array<{
  formatType?: string
  srcSet: string
  media?: string
}>

export const createFormatArray = ({
  formats,
  breakpoints,
}: CreateFormatParams) => {
  const formatArray: FormatArray = []

  // sets source orders. TODO: mobile / desktop values in UI?

  Object.entries(formats).forEach(([k, v]) => {
    if (v.mobile && breakpoints) {
      formatArray.push({
        formatType: k,
        srcSet: `${v.mobile}`,
        media: breakpoints.mobileMedia ?? undefined,
      })
    } else if (v.mobile) {
      formatArray.push({
        formatType: k,
        srcSet: `${v.mobile}`,
        media: '(max-width: 719px)',
      })
    }

    if (v.tablet) {
      formatArray.push({
        formatType: k,
        srcSet: `${v.tablet}`,
        media: breakpoints?.tabletMedia ?? undefined,
      })
    }

    if (v.desktop) {
      formatArray.push({
        formatType: k,
        srcSet: `${v.desktop}`,
        media: breakpoints?.desktopMedia ?? undefined,
      })
    }

    if (v.wide && breakpoints) {
      formatArray.push({
        formatType: k,
        srcSet: `${v.wide}`,
        media: breakpoints.wideMedia ?? undefined,
      })
    } else if (v.wide) {
      formatArray.push({
        formatType: k,
        srcSet: `${v.wide}`,
        media: '(min-width: 720px)',
      })
    }

    if (!breakpoints) {
      formatArray.push(
        v.x2
          ? {
              formatType: k,
              srcSet: `${v.x1} 1x, ${v.x2} 2x`,
              media: '(min-width: 720px)',
            }
          : { formatType: k, srcSet: `${v.x1} 1x` }
      )
    }
  })

  return formatArray
}
