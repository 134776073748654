import { CroppableImage, PrismicImage } from '@hugsmidjan_is/prismic/types'

import {
  Breakpoints,
  defaultBreakpoints,
  defaultImageSizes,
  ViewportSizes,
  WidthHeight,
} from 'utils/cleverCropping'
import { getFileExt } from 'utils/getFileExt'

import { Picture } from './Picture'

type Props = {
  image: CroppableImage
  width?: number
  className?: string
  lazy?: boolean
  componentSizes?: ViewportSizes
  componentBreakpoints?: Breakpoints
  imagePosition?: { x?: number; y?: number }
}

type MobileCroppableImage = CroppableImage & {
  focus_point_mobile: PrismicImage
}

function cropRectToUrl(
  imageUrl: string,
  rect: Array<number>,
  size: WidthHeight
) {
  return `${imageUrl}&rect=${rect[0]},${rect[1]},${rect[2]},${rect[3]}&w=${size.width}&h=${size.height}`
}

function removePossibleRect(imageUrl: string) {
  return imageUrl.split('&rect')[0]
}

function extractRectFromUrl(imageUrl: string) {
  const params = new URLSearchParams(imageUrl)
  const rectParams = params.get('rect')

  if (rectParams) {
    return rectParams.split(',').map(Number)
  }

  return null
}

export const cropImageToAspect = (
  image: CroppableImage,
  size: WidthHeight,
  mobile: boolean
) => {
  let cropRect = extractRectFromUrl(image.url)

  if (!cropRect) {
    if ('focus_point' in image && mobile) {
      cropRect = extractRectFromUrl(
        (image as MobileCroppableImage).focus_point.url
      )
    } else {
      cropRect = extractRectFromUrl(image?.focus_point?.url ?? '')
    }
  }

  if (!cropRect) {
    cropRect = [0, 0, image.dimensions.width, image.dimensions.height]
  }

  let [rectX, rectY, rectWidth, rectHeight] = cropRect

  const desieredAspect = size.width / size.height

  const possibleNewWidth = Math.ceil(desieredAspect * image.dimensions.height)
  const possibleNewHeight = Math.ceil(image.dimensions.width / desieredAspect)

  const newWidth =
    possibleNewWidth < image.dimensions.width
      ? possibleNewWidth
      : image.dimensions.width

  const newHeight =
    possibleNewHeight < image.dimensions.height
      ? possibleNewHeight
      : image.dimensions.height

  const widthGrowsBy = newWidth - rectWidth
  let newRectX = Math.ceil(rectX - widthGrowsBy / 2)
  if (image.dimensions.width < newRectX + newWidth) {
    newRectX = image.dimensions.width - newWidth
  }
  rectX = newRectX > 0 ? newRectX : 0
  rectWidth = newWidth

  const heighGrowsBy = newHeight - rectHeight
  let newRectY = Math.ceil(rectY - heighGrowsBy / 2)
  if (image.dimensions.height < newRectY + newHeight) {
    newRectY = image.dimensions.height - newHeight
  }
  rectY = newRectY > 0 ? newRectY : 0
  rectHeight = newHeight

  const newCrop = [rectX, rectY, rectWidth, rectHeight]

  return cropRectToUrl(removePossibleRect(image.url), newCrop, size)
}

export const generateCroppedImageUrls = (
  image: CroppableImage,
  imageSizes: ViewportSizes = defaultImageSizes
) => {
  if ('x1' in imageSizes) {
    return { x1: cropImageToAspect(image, imageSizes.x1, false) }
  } else {
    const { wide, desktop, tablet, mobile } = imageSizes
    if (
      // if all image sizes are equal we don't need to print out a whole lot of the same image
      wide.width === desktop.width &&
      tablet.width === mobile.width
    ) {
      return { x1: cropImageToAspect(image, wide, false) }
    }

    return {
      wide: cropImageToAspect(image, wide, false),
      desktop: cropImageToAspect(image, desktop, false),
      tablet: cropImageToAspect(image, tablet, true),
      mobile: cropImageToAspect(image, mobile, true),
    }
  }
}

export function PrismicCroppablePicture({
  image,
  width,
  className,
  lazy,
  componentSizes = defaultImageSizes,
  componentBreakpoints = defaultBreakpoints,
  imagePosition,
}: Props) {
  if (!image || !image.url) {
    return null
  }

  const formats: Record<
    string,
    { wide?: string; desktop?: string; tablet?: string; mobile?: string }
  > = {}

  const extension = getFileExt(image.url) ?? 'jpg'

  const imageUrls = generateCroppedImageUrls(image, componentSizes)
  formats[extension] = imageUrls

  const src = imageUrls.x1 ?? `${image.url}&w=${width ?? 3000}`

  return (
    <Picture
      src={src ?? ''}
      alt={image.alt ?? ''}
      formats={formats}
      className={className}
      lazy={lazy}
      breakpoints={componentBreakpoints}
      imagePosition={imagePosition}
    />
  )
}
